<template>
  <v-container>
    <div style="text-align: center">
      <v-alert
        v-if="responseStatus.alertStatus"
        :type="responseStatus.type"
        width="100%"
        style="z-index: 9999; text-align: left"
        dense
        dismissible
      >
        {{ responseStatus.text }}
      </v-alert>
    </div>
    <v-row justify="space-around">
      <v-card max-width="960" ref="form">
        <v-card-title>Sign up</v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12">
                <v-text-field
                  v-model="regForm.clinic.clinicName"
                  :rules="nameRules"
                  label="Clinic Name"
                  required
                  id="clinicName"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-text-field
                  v-model="regForm.clinic.clinicTelNo"
                  label="Clinic Tel."
                  :rules="[(v) => !!v || 'This field is required']"
                  required
                  id="clinicTelNo"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" sm="12">
                <v-text-field
                  v-model="regForm.firstname"
                  :rules="nameRules"
                  label="First Name"
                  required
                  id="firstname"
                ></v-text-field>
              </v-col>

              <v-col md="6" sm="12">
                <v-text-field
                  v-model="regForm.lastname"
                  :rules="nameRules"
                  label="Last Name(Family Name/Surname)"
                  required
                  id="lastname"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" sm="12">
                <v-text-field
                  v-model="regForm.email"
                  :rules="emailRules"
                  label="Email"
                  type="email"
                  required
                  id="email"
                ></v-text-field>
              </v-col>

              <v-col md="6" sm="12">
                <v-select
                  v-model="regForm.practitioner"
                  :items="items"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Practitioner"
                  required
                  id="practitioner"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6">
                <v-text-field
                  v-model="regForm.mobile"
                  :rules="mobileRules"
                  label="Mobile"
                  required
                  id="mobile"
                ></v-text-field>
              </v-col>
              <v-col md="6">
                <v-text-field
                  v-model="regForm.licenseId"
                  label="Doctor License ID"
                  required
                  :rules="[(v) => !!v || 'This field is required']"
                  id="licenseId"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" sm="12">
                <v-text-field
                  v-model="regForm.password"
                  :counter="12"
                  :rules="passwordRules"
                  label="Password"
                  required
                  type="password"
                  maxlength="18"
                  id="password"
                  ref="password"
                ></v-text-field>
              </v-col>
              <v-col md="6" sm="12">
                <v-text-field
                  v-model="regForm.confirmPassword"
                  label="Confirm Password"
                  :rules="confirmPasswordRules"
                  required
                  type="password"
                  maxlength="18"
                  id="confirmPassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-title>Shipping Address/Clinic Address</v-card-title>

        <v-card-text>
          <address-form
            @updateAddress="updateAddress"
            @updateValid="updateValid"
            v-bind:chkValid="chkValid"
            v-bind:editAddress="editAddress"
          ></address-form>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <!--v-row>
              <v-col>
                <v-checkbox
                  v-model="agree"
                  :rules="[(v) => !!v || 'You must agree to continue!']"
                  label="Do you agree?"
                  required
                ></v-checkbox>
              </v-col>
            </v-row-->

            <v-row>
              <v-col>
                <v-btn :disabled="!valid" class="mr-4" color="primary" @click="validate">
                  Register
                </v-btn>

                <v-btn class="mr-4" @click="reset" color="warning"> Reset </v-btn>
                <v-btn class="mr-4" @click="back"> Back </v-btn>

                <!--v-btn color="warning" @click="resetValidation"> Reset Validation </!--v-btn-->
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import AddressForm from "../../library/postcode/postcode-form.vue";
import axios from "axios";
export default {
  name: "SignupForm",
  components: {
    "address-form": AddressForm,
  },
  props: {
    memberType: {
      type: String,
    },
    userType: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data: () => ({
    //id: "",
    info: "",
    editAddress: {},
    responseStatus: {
      alertStatus: false,
      type: "",
      alertMessage: "",
    },
    chkValid: false,
    valid: true,
    agree: false,
    closeInSec: process.env.VUE_APP_ALERT_DISPLAY * 1000,
    url: process.env.VUE_APP_LOYALTY_API_URI,
    regForm: {
      clinic: { clinicName: "", clinicTelNo: "" },
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      practitioner: "",
      licenseId: "",
      password: "",
      confirmPassword: "",
      member_type:"Doctor",
      vip: 0,
    },
    hasError: false,
    address: {
      address: "",
      province: "",
      district: "",
      tambon: "",
      zipcode: "",
    },
    name: "",
    nameRules: [
      (v) => !!v || "This field is required",
      //(v) => (v && v.length <= 100) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /[^@]+@[^@]+.[a-zA-Z]{2,6}/.test(v) || "E-mail must be valid",
    ],
    mobile: "",
    mobileRules: [
      (v) => !!v || "Mobile is required",
      (v) => /(^0)(6|8|9)[0-9]{8,8}/.test(v) || "Mobile must be valid",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{7,25}$/.test(
          v
        ) ||
        "Password must have Numberic, Lowercase, Uppercase, special charactor ( !@#$%^&* ) and must between 6-18 charactors ",
    ],
    confirmPassword: "",
    confirmPasswordRules: [
      (v) => !!v || "Confirm password is required",
      (v) => {
        let pwd = document.getElementById("password");
        if (pwd != "undefined") {
          if (document.getElementById("password").value == v) {
            return true;
          } else {
            return "Confirm password must equal password ";
          }
        }
      },
    ],

    requiredRules: [(v) => !!v || "This field is required"],
    items: ["Dentist", "Orthodontist", "Other Specialist"],
  }),
  computed: {},
  mounted() {
    console.log("---mounted--");
    console.log(this.id);
    console.log(this.userType);
    this.id = this.$route.params.id;
    if (typeof this.id !== "undefined") {
      async () => {
        await this.init();
      };
    }
  },
  methods: {
    requiredFields() {
      return {
        editAddress: {},
        custId: process.env.VUE_APP_OGF_CUST_ID,
        clinic: {
          clinicName: this.regForm.clinic.clinicName,
          clinicTelNo: this.regForm.clinic.clinicTelNo,
          address: this.address.address,
          postcode: this.address.postcode,
          province: this.address.province,
          district: this.address.district,
          tambon: this.address.tambon,
        },
        firstname: this.regForm.firstname,
        lastname: this.regForm.lastname,
        mobile: this.regForm.mobile,
        email: this.regForm.email,
        practitioner: this.regForm.practitioner,
        licenseId: this.regForm.licenseId,
        password: this.regForm.password,
        address: this.address.address,
        postcode: this.address.postcode,
        province: this.address.province,
        district: this.address.district,
        tambon: this.address.tambon,
        member_type: "Doctor",
        status: "Waiting",
        vip: 0,
      };
    },

    back() {
      this.$router.push("/doctor");
    },

    async init() {
      try {
        let payload = {
          doctorId: this.id,
        };
        console.log(payload);
        await axios
          .post(
            this.url + "/api/bfc/v1/doctor/getInfo",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log("init");
            console.log(response);

            if (response.data.statusCode == 0) {
              this.responseStatus = {
                status: "Success",
                code: 0,
                color: "green",
                type: "success",
                text: "สำเร็จ",
                alertStatus: true,
              };

              this.info = response.data.data[0];
              let clinic = this.info.clinic;
              this.editAddress = {
                clinicAddress: clinic.clinicName,
                postcode: clinic.postcode,
                province: clinic.province,
                tambon: clinic.tambon,
                district: clinic.district,
              };

              //this.$router.back();
            }
          });
      } catch (e) {
        console.log(e);
      }
    },

    async validate() {
      //this.$refs.form.validate();
      /*
      Object.keys(this.regForm).forEach((f) => {
        //console.log(this.requiredField[f]);
        if (!this.regForm[f] && this.regForm[f] != 0) {

          this.hasError = true;
          document.getElementById(f).blur();

        }
      });
      */
      console.log("validate");
      this.hasError = false;
      let breakForEachException = {};

      let uri =
        typeof this.editAddress.clinicAddress === "undefined"
          ? "/api/bfc/v1/doctor/signup"
          : "/api/bfc/v1/doctor/updateProfile";

      try {
        await Object.keys(this.regForm).forEach((f) => {
          //console.log(this.requiredField[f]);
          if (!this.regForm[f] && this.regForm[f] != 0) {
            this.hasError = true;
            document.getElementById(f).focus();
            throw breakForEachException;
          }
        });
        //console.log("validation");
        this.chkValid = true;

        if (!this.hasError) {
          //this.valid = true;
          // send
          let payload = {
            ...this.requiredFields(),
          };
          await axios
            .post(
              this.url + uri,
              payload, // need to change
              {
                headers: {
                  // Overwrite Axios's automatically set Content-Type
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              console.log(response);
              if (response.data.statusCode == 0) {
                this.responseStatus = {
                  status: "Success",
                  code: 0,
                  color: "green",
                  type: "success",
                  text: "ลงทะเบียนสำเรี็จ",
                  alertStatus: true,
                };
                alert("ลงทะเบียนสำเร็จ  โปรดรออนุมัตจากผู้ดูแลระบบ ");
                this.$router.back();
              } else {
                console.log("error");
                this.responseStatus = {
                  status: "Failed",
                  code: response.data.statusCode,
                  color: "red",
                  type: "error",
                  text: response.data.data.responseStatus.alertMessage,
                  alertStatus: true,
                };
              }
            });
        }
      } catch (e) {
        if (e != breakForEachException) throw e;
      }
    },
    reset() {
      this.regForm = {
        clinic: { clinicName: "", clinicTelNo: "" },
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        practitioner: "",
        licenseId: "",
        password: "",
        confirmPassword: "",
        vip: 0,
      };
      this.address = {};
      //console.log(this.regForm);
      //console.log("reset");
    },
    resetValidation() {
      //this.$refs.form.resetValidation();
    },
    updateAddress(addr) {
      //console.log("update Address");
      //console.log(addr);
      //this.requiredFields();

      this.address = {
        address: addr.address,
        postcode: addr.postcode,
        province: addr.province,
        district: addr.district,
        tambon: addr.tambon,
      };
      //console.log(this.address);
    },
    updateValid(val) {
      this.chkValid = val;
    },
    closeInTime(sec) {
      console.log(this.responseStatus);
      setTimeout(() => {
        console.log("call close in time");
        this.responseStatus.alertStatus = false;
      }, sec);
    },
  },
  watch: {
    address: {
      handler() {
        //console.log(this.address);
      },
    },
    responseStatus: {
      handler() {
        console.log("handler response status");
        console.log(this.responseStatus);
        if (this.responseStatus.alertStatus === true) {
          this.closeInTime(this.closeInSec);
        }
      },
    },
  },
};
</script>
